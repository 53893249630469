import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultContactBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_contact_banner', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListContactBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_contact_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getContactBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_contact_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContactBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_contact_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editContactBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_contact_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContactBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_contact_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    contactBannerOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/contact_banner_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageContactBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_contact_banner/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
